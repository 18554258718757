var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'},[_c('div',{staticClass:"mt-5"},[_c('Breadcrumbs',{attrs:{"title":"Add New Questions","items":_vm.items}}),_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mb-8"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('div',[_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.questions),function(items,i){return _c('v-expansion-panel',{key:i,staticClass:"mb-3 rounded-lg",staticStyle:{"border":"1px solid #ddd"},attrs:{"expand":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel-header',[_c('span',{staticClass:"font-weight-bold text-h6"},[_vm._v(" Number "+_vm._s(i + 1)+" ")]),_c('v-spacer'),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(i)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1),_c('v-divider'),_c('v-expansion-panel-content',[_c('div',{staticClass:"mt-7"},[_c('ValidationProvider',{attrs:{"name":items.instruction,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Instructions","autocomplete":"off","color":"#F05326"},model:{value:(items.instruction),callback:function ($$v) {_vm.$set(items, "instruction", $$v)},expression:"items.instruction"}})]}}],null,true)}),_c('div',[_vm._v("Question")]),_c('v-card',{staticClass:"mb-4",attrs:{"flat":"","outlined":""}},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('div',{staticClass:"px-5",staticStyle:{"width":"1000px"}},[_c('audio-attachment',{class:Object.keys(items.fileQuestion.media_data).length == 0 || Object.keys(items.fileQuestion.media_data.media).length == 0 ? 'float-right' : '',attrs:{"src":items.fileQuestion.media_data},on:{"update":function($event){return _vm.$set(items.fileQuestion, 'media_data', Object.assign({}, items.fileQuestion.media_data,
                                $event))}}})],1)],1)]),_vm._l((items.options),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"text-right caption mr-10"},[_vm._v("Correct Answer")]),_c('ValidationProvider',{attrs:{"name":item.text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"options",attrs:{"outlined":"","dense":"","error-messages":errors,"label":("Option " + (index + 1)),"autocomplete":"off","color":"#F05326"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('ValidationProvider',{attrs:{"name":items.selectedValue,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors},model:{value:(items.selectedValue),callback:function ($$v) {_vm.$set(items, "selectedValue", $$v)},expression:"items.selectedValue"}},[_c('v-radio',{attrs:{"color":"#F05326","value":item.text}})],1)]}}],null,true)})]},proxy:true}],null,true),model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}},[_c('v-icon',{staticClass:"mt-4",attrs:{"slot":"append-outer","color":"red"},on:{"click":function($event){return _vm.removeOption(i,index)}},slot:"append-outer"},[_vm._v(" mdi-close ")])],1)]}}],null,true)})],1)}),_c('div',[_c('v-btn',{attrs:{"color":"#F05326","text":"","dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.addOptions(i)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Options ")],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"red","text":"","dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.clearAnswer(i)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Clear Answer ")],1)],1),_c('div',{staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"name":items.correct_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Correct Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(items.correct_explan),callback:function ($$v) {_vm.$set(items, "correct_explan", $$v)},expression:"items.correct_explan"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":items.incorrect_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Incorrect Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(items.incorrect_explan),callback:function ($$v) {_vm.$set(items, "incorrect_explan", $$v)},expression:"items.incorrect_explan"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":("" + (items.point)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","error-messages":errors,"label":"Point","autocomplete":"off","color":"#F05326"},model:{value:(items.point),callback:function ($$v) {_vm.$set(items, "point", $$v)},expression:"items.point"}})]}}],null,true)})],1)],2)])],1)}),1)],1),_c('div',{staticClass:"mt-7"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"outlined":"","dense":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":function($event){return _vm.addQuestion()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Question ")],1),_c('v-btn',{staticClass:"text-capitalize white--text float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":_vm.setData}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"text-capitalize black--text mr-5 float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F5F5F5"},on:{"click":function($event){return _vm.$router.push(("/questions/" + (_vm.$route.params.id_course) + "/" + (_vm.$route.params.id_topic) + "/" + (_vm.$route.params.id_activity) + "/" + (_vm.$route.params.activity_type)))}}},[_vm._v(" Cancel ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }